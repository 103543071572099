/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { headerBreak } from '@/components/content/Header/styles/break';
import { SxProps } from '@mui/material';

export const headerNavBarContainerSX: SxProps = {
	borderRadius: 0,
	overflow: 'visible',
	paddingBottom: '2px',
	display: headerBreak({ mobile: 'none', desktop: 'block' }),
	boxShadow: '0px 4px 5px 0px rgba(0, 0, 0, 0.08)',
	color: 'text.primary',
	'&:hover': {
		color: 'text.primary',
	},
};
