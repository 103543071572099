/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { headerNavBarDropMenuItemCustomSX } from '@/components/content/Header/styles/navBar/dropMenuItemCustom';
import { PageLink } from '@/data/Navigation';
import { Stack, MenuList, MenuItem, Typography, Collapse } from '@mui/material';
import React, { FC } from 'react';
import { HeaderNavBarMenuItem } from '@/components/content/Header/parts/NavBarMenuItem';
import { renderContent } from '@/utils/renderContent';
import { useContentEvents } from '@/data/Content/_ContentEvents';
import { ProcessedContent } from '@/data/types/Marketing';
import { headerNavBarEspotSX } from '@/components/content/Header/styles/navBar/NavBarEspot';


export const HeaderNavBarMenuListCustom: FC<{
	tree?: PageLink[];
	setDropDownFull: React.Dispatch<React.SetStateAction<boolean>>;
	setDropdownState: React.Dispatch<React.SetStateAction<boolean>>;
	dropDownFull: boolean;
	topCategorySuppEspotData: ProcessedContent[] | undefined;
}> = ({ tree, dropDownFull, topCategorySuppEspotData, setDropDownFull, setDropdownState }) => {
	const [eSpotContent, setEspotContent] = React.useState<ProcessedContent[] | undefined | null>(null);
	const { onContentClick } = useContentEvents();
	const setHandler = React.useCallback(
		(data: ProcessedContent[] | undefined | null, title: string) => {
			if (data && title) {
				setTimeout(() => setEspotContent(data), 300);
			}
		}, []
	);
	return tree ? (
		<Stack direction={'row'} sx={{ width: '100%' }}>
			<MenuList sx={headerNavBarDropMenuItemCustomSX({
				dropDownFull,
			})}>
				{tree.map(({ label, url, identifier }, index) => (
					identifier ? <HeaderNavBarMenuItem setDropdownState={setDropdownState} key={index + identifier as string} url={url} label={label} identifier={identifier} index={index} setDropDownFull={setDropDownFull} dropDownFull={dropDownFull} setHandler={setHandler} /> : null
				))}
				{topCategorySuppEspotData ? <MenuItem>
					{topCategorySuppEspotData?.map((content) => renderContent(content, onContentClick(content)))}
				</MenuItem> : null}
			</MenuList>
			{
				eSpotContent ? (
					<Stack direction="column" sx={headerNavBarEspotSX}>

						{eSpotContent?.map((content) => renderContent(content, onContentClick(content)))}

					</Stack>
				) : null}

		</Stack>

	) : null;
};
