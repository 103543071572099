/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const NavBarDropDownMenuSX = ({
	dropDownFull,
	dropDownState,
}: {
	dropDownFull: boolean;
	dropDownState: boolean;
}): SxProps => ({
	position: 'absolute',
	backgroundColor: '#fff',
	top: '100%',
	left: dropDownFull ? 0 : 'auto',
	width: dropDownFull ? '100%' : 'auto',
	height: dropDownState ? 'auto' : '0',
	marginLeft: !dropDownFull ? '-5rem' : '',
	marginTop: '1px',
	zIndex: 1,
	borderBottomLeftRadius: '5px',
	borderBottomRightRadius: '5px',
	overflow: 'hidden',
});
