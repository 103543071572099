import React from 'react';
import { FC, useMemo } from 'react';
import { Button } from '@mui/material';
import { SkipToLinkSX } from '@/components/content/Header/styles/SkipToLink';
import { useLocalization } from '@/data/Localization';


export const SkipToLink: FC = () => {
  const HeaderLabels = useLocalization('Header');
  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const container: (HTMLElement | null) = document.querySelector('main');

    if (container) {
      container.tabIndex = -1;
      container.focus();
      setTimeout(() => container.removeAttribute('tabindex'), 1000);
    }
  };

  return (
    <Button tabIndex={0} sx={SkipToLinkSX} onClick={onClick}>{HeaderLabels.Actions.SkipToMainContent.t()}</Button>
  );
};


