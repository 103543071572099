/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { HeaderNavBarMenuListCustom } from '@/components/content/Header/parts/NavBarMenuListCustom';
import { headerNavBarMenuItemSX } from '@/components/content/Header/styles/navBar/navbarItem';
import { PageLink } from '@/data/Navigation';
import { Box, Button, Stack, Grow, Typography, Collapse } from '@mui/material';
import React, { useEffect } from 'react';
import { NavBarDropDownMenuSX } from '@/components/content/Header/styles/NavBarDropDownMenu';
import { useContentRecommendation } from '@/data/Content/ContentRecommendation';
import { NAVIGATION_MARKETING_SPOT_SUFFIX, NAVIGATION_ADDITIONAL_LINKS_MARKETING_SPOT_SUFFIX } from '@/data/constants/marketing';
import { renderContent } from '@/utils/renderContent';
import { useContentEvents } from '@/data/Content/_ContentEvents';
import { headerNavBarEspotSX } from '@/components/content/Header/styles/navBar/NavBarEspot';
import { useNextRouter } from '@/data/Content/_NextRouter';


type Props = {
	tree: PageLink[];
	label: string;
	identifier?: string
	setBgDropState: React.Dispatch<React.SetStateAction<boolean>>
};

export const HeaderNavBarDropMenuCustom = React.forwardRef(
	({ tree, label, identifier, setBgDropState }: Props) => {
		const dropRef = React.useRef<HTMLInputElement | null>(null);
		const [dropDownState, setDropdownState] = React.useState<boolean>(false);
		const [dropDownFull, setDropDownFull] = React.useState<boolean>(false);
		const { onContentClick } = useContentEvents();
		const router = useNextRouter();
		const handleHover = React.useCallback(
			(state: boolean) => {
				if (!dropRef.current) return;
				setDropdownState(state);
			}, [setDropdownState]
		);

		useEffect(() => {
			if (dropDownState) {
				router.events.on('routeChangeStart', async () => setDropdownState(false)
				);
			}
		}, [dropDownState, router.events]);

		const topCatId = identifier + NAVIGATION_MARKETING_SPOT_SUFFIX;
		const topCatAltId = identifier + NAVIGATION_ADDITIONAL_LINKS_MARKETING_SPOT_SUFFIX;
		const { data: topCategoryEspotData } = useContentRecommendation(topCatId);
		const { data: topCategorySuppEspotData } = useContentRecommendation(topCatAltId);
		return (

			<Box tabIndex={0} onFocus={() => handleHover(true)} onBlur={() => handleHover(false)} onMouseOver={() => handleHover(true)} onMouseOut={() => handleHover(false)}>
				<Button component='span' tabIndex={-1} disableRipple={true} sx={headerNavBarMenuItemSX({ dropDownState })} >
					{label}
				</Button>
				{
					tree && tree.length > 0 ?


						<Stack direction="row" ref={dropRef} sx={NavBarDropDownMenuSX({ dropDownFull, dropDownState })} >
							<Collapse in={dropDownState} onEntered={() => setBgDropState(true)} onExit={() => setBgDropState(false)} timeout={{ enter: 500 }} sx={{ transitionDelay: '.5s' }}>
								<HeaderNavBarMenuListCustom setDropdownState={setDropdownState} topCategorySuppEspotData={topCategorySuppEspotData} tree={tree} setDropDownFull={setDropDownFull} dropDownFull={dropDownFull} />
							</Collapse>

						</Stack>

						:
						topCategoryEspotData ?
							<Stack direction="row" ref={dropRef} sx={NavBarDropDownMenuSX({ dropDownFull: true, dropDownState })} >
								<Collapse in={dropDownState} onEntered={() => setBgDropState(true)} onExit={() => setBgDropState(false)} timeout={{ enter: 500 }} sx={{ transitionDelay: '.5s' }}>
									<Stack direction="column" sx={headerNavBarEspotSX}>
										{topCategoryEspotData?.map((content) => renderContent(content, onContentClick(content)))}
									</Stack>
								</Collapse>
							</Stack> : null
				}
			</Box >
		);
	}
);
