/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ID } from '@/data/types/Basic';

import { getChildContentItems } from '@/data/utils/getChildContentItems';
import { useMemo } from 'react';
import { getHeader } from '@/core/data/Content/Header';

const useHeader = (id: ID) => ({
	contentItems: useMemo(() => getChildContentItems(id), [id]),
});

export { getHeader, useHeader };
