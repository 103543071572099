import { SxProps } from '@mui/material';

export const headerDesktopDrawerSX: SxProps = {
	backgroundColor: 'background.modal',
	width: '375px',
	height: '100%',
	p: 3,
	ul: { borderTop: '1px solid #ddd', marginTop: '20px' },
	li: { padding: 1, borderBottom: '1px solid #ddd', margin: '8px 0' },
	a: {
		paddingLeft: 0,
		fontSize: 18,
		fontWeight: 400,
		color: 'text.main',
	},
	'a:hover': {
		textDecoration: 'underline',
		color: 'text.main',
	},
};


export const headerDesktopDrawerButtonSX: SxProps = {
	backgroundColor: 'background.modal',
	padding: 0,
	paddingTop: 1,
	'& h6.MuiTypography-root.MuiTypography-subtitle1': {
		fontSize: 18,
		fontWeight: 400,
		lineHeight: '27px',
		color: 'text.main',
	},
	'&:hover': {
		backgroundColor: 'background.modal',
		textDecoration: 'underline',
	},
};
