/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

export const numberInputsSX = {
	input: {
		textAlign: 'center',
		px: 1,
	},
};
