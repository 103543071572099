import { SxProps } from '@mui/material';
export const headerNavBarSubItemSX = ({
	isParent,
	dropDownFull,
}: {
	isParent: boolean;
	dropDownFull: boolean;
}): SxProps => ({
	display: 'flex',
	minWidth: dropDownFull ? '375px' : '300px',
	'&.MuiButton-text': {
		p: '1.3rem 0 1.3rem .5rem',
		backgroundColor: 'transparent',
		color: 'text.main',
		fontSize: isParent ? '16px' : '14px',
		borderRadius: 0,
		justifyContent: 'space-between',
		'&:hover': {
			color: 'primary.main',
		},
	},
});
