import { FC, ReactNode } from 'react';
import Drawer from '@mui/material/Drawer';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { pdpDriverContainerButtonSX, pdpDriverContainerSX } from '@/components/content/ProductDetails/styles/sliderDrawer';

export const SliderDrawer: FC<{ isModalOpen: boolean; setIsModalOpen: (value: boolean) => void; childElement: ReactNode }> = ({
  isModalOpen,
  setIsModalOpen,
  childElement,
}) => (
  <Drawer
    anchor="right"
    open={isModalOpen}
    sx={pdpDriverContainerSX}
    variant="temporary"
    ModalProps={{
      keepMounted: true,
    }}
  >
    <Box sx={pdpDriverContainerButtonSX} onClick={() => setIsModalOpen(false)}><CloseIcon fontSize='small' /></Box>
    {childElement}
  </Drawer>
);
