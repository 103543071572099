
import { SxProps } from '@mui/material';

export const SkipToLinkSX: SxProps = {
  position: 'absolute',
  transform: 'translateX(-200%)',
  ':focus': {
    position: 'static',
    transform: 'none',
  },
};
