/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { headerBreak } from '@/components/content/Header/styles/break';
import { SxProps } from '@mui/material';

export const headerContainerSX: SxProps = {
	backgroundColor: 'background.paper',
	borderRadius: 0,
	top: 0,
	position: headerBreak({ mobile: 'sticky', desktop: 'relative' }),
	boxShadow: headerBreak({mobile: '0px 4px 5px 0px rgba(0, 0, 0, 0.08)', desktop: 'none'}),
	zIndex: 20,
	overflow: 'visible',
	'& #logo-wrapper': {
		img: {
			mt: 0.5,
			width: { md: 175, xs: 144 },
		},
	},
};
