/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const headerMobileDrawerSX: SxProps = {
	width: '90vw',
	p: 2,
	'> .MuiStack-root': {
		paddingBottom: 2,
		borderBottom: 'solid 1px',
		borderBottomColor: 'border.gray',
	},
};
