/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const headerNavBarDropMenuItemCustomSX = ({
	dropDownFull,
}: {
	dropDownFull: boolean;
}): SxProps => ({
	padding: '5px',
	color: 'text.primary',
	a: {
		display: 'flex',
		minWidth: dropDownFull ? '333px' : '300px',
		maxWidth: dropDownFull ? '333px' : '300px',
		color: 'text.main',
		borderRadius: 0,
		p: '1.3rem 0 1.3rem .5rem',
		justifyContent: 'start',
		alignItems: 'center',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	'.MuiMenuItem-root': {
		padding: 0,
	},
	'.MuiMenuItem-root:not(:last-child)': {
		borderBottom: 'solid 1px #ddd',
	},
	'@media (hover: hover)': {
		'&:hover': {
			color: 'primary.main',
			backgroundColor: 'transparent',
		},
	},
	'@media (hover: none)': {
		'&:focus': {
			color: 'inherit',
			backgroundColor: 'inherit',
		},
	},
});
