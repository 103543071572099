/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { AdvantageMembershipFlyout } from '@/components/content/Cart/parts/AdvantageMembershipFlyout';
import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { ADVANTAGE_MEMBER_PRODUCT, ADVANTAGE_MEMBER_PRODUCT_PART_NUMBER, ADVANTAGE_TRIAL_PRODUCT, ADVANTAGE_TRIAL_PRODUCT_PART_NUMBER } from '@/data/constants/marketing';
import { ID } from '@/data/types/Basic';
import { ContentItemProperties } from '@/data/types/Slot';
import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';

type props = {
    id: ID;
    headerGlobalBanner?: ContentItemProperties
}

export const HeaderGlobalBanner: FC<props> = ({ id, headerGlobalBanner }) => {
    const [jjkDrawerOpen, setJJKDrawerOpen] = useState<boolean>(false);
    const [advProdType, setAdvProdType] = useState<string>('');
    const [advProdNumb, setAdvProdNumb] = useState<string>('');

    const queryButtonTypeForEspot = (buttonType: string) => {
        if (buttonType === 'trial') {
            setAdvProdType(ADVANTAGE_TRIAL_PRODUCT);
            setAdvProdNumb(ADVANTAGE_TRIAL_PRODUCT_PART_NUMBER);
            setJJKDrawerOpen(true);
        } else if (buttonType === 'full') {
            setAdvProdType(ADVANTAGE_MEMBER_PRODUCT);
            setAdvProdNumb(ADVANTAGE_MEMBER_PRODUCT_PART_NUMBER);
            setJJKDrawerOpen(true);
        }
        // add as many other blocks as needed for other flyout types if applicable.
    };

    useEffect(() => {
        const advTrialProdButtons = document?.querySelectorAll('.advantage-trial-product-btn');
        const advFullProdButtons = document?.querySelectorAll('.advantage-full-product-btn');
        const callQueryButtonTypeForEspot = (buttonType: string) => {
            queryButtonTypeForEspot(buttonType);
        };
        advTrialProdButtons.forEach((button) =>
            button.addEventListener('click', () => {
                callQueryButtonTypeForEspot('trial');
            }
            ));
        advFullProdButtons.forEach((button) =>
            button.addEventListener('click', () => {
                callQueryButtonTypeForEspot('full');
            }
            ));

        return () => {
            advTrialProdButtons?.forEach(() => removeEventListener('click', () => {
                callQueryButtonTypeForEspot('');
            }));
            advFullProdButtons?.forEach(() => removeEventListener('click', () => {
                callQueryButtonTypeForEspot('');
            }));
        };
    }, []);

    return (
        <Box>
            <ContentRecommendation id={`${id}-${headerGlobalBanner?.emsName}`} properties={headerGlobalBanner} />
            <AdvantageMembershipFlyout
                jjkDrawerOpen={jjkDrawerOpen}
                setJJKDrawerOpen={setJJKDrawerOpen}
                advMembershipPartNumEspot={advProdNumb}
                advMembershipContentEspotName={advProdType} />
        </Box>
    );
};
