import { SxProps } from '@mui/material';

export const backDropSX = ({ bgDropState }: { bgDropState: boolean }): SxProps => ({
	position: 'fixed',
	top: 0,
	width: '100vw',
	height: '100vh',
	backgroundColor: '#000',
	zIndex: 9,
	opacity: bgDropState ? 0.7 : 0,
	visibility: bgDropState ? 'visible' : 'hidden',
	transition:
		'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 3000ms',
});
