import { SxProps } from '@mui/material';

export const headerNavBarDropMenuItemMobileSX: SxProps = {
	padding: '5px',
	a: {
		color: 'text.primary',
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		padding: 0,
		alignItems: 'center',
	},
	'.MuiMenuItem-root': {
		borderBottom: 'solid 1px #ddd',
	},
	'@media (hover: hover)': {
		'&:hover': {
			color: 'primary.main',
			backgroundColor: 'transparent',
		},
	},
	'@media (hover: none)': {
		'&:focus': {
			color: 'inherit',
			backgroundColor: 'inherit',
		},
	},
};
