
import { advantageSubHeaderSX } from '@/components/content/Header/styles/advantageSubHeader';
import { useAdvantageEspots } from '@/data/Content/AdvantageEspots';
import { useContentEvents } from '@/data/Content/_ContentEvents';
import { renderContent } from '@/utils/renderContent';
import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';

export const AdvantageSubMenu: FC = () => {
    const { advantageEspotSubMenu } = useAdvantageEspots();
    const { onContentClick } = useContentEvents();
    const router = useRouter();
    const path = router?.asPath;

    useEffect(() => {
        if (typeof document !== 'undefined' && advantageEspotSubMenu !== undefined && advantageEspotSubMenu?.length > 0) {
            const navItems = document?.querySelectorAll('#advantage_list li a');
            navItems ? navItems?.forEach((nav) => nav?.getAttribute('href') === path ? nav?.parentElement?.classList.add('adv-active-link') : nav?.parentElement?.classList.remove('adv-active-link')) : null;
        }
    }, [advantageEspotSubMenu, path]);
    return advantageEspotSubMenu ? (<Box sx={advantageSubHeaderSX}>
        {advantageEspotSubMenu?.map((content) => renderContent(content, onContentClick(content)))}
    </Box>) : null;
};
