/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { headerBreak } from '@/components/content/Header/styles/break';
import { SxProps } from '@mui/material';

export const headerMiniCartBadgeSX: SxProps = {
	'.MuiBadge-badge': {
		right: '10px',
		top: '-14px',
		border: `none`,
		height: 'auto',
		fontWeight: '400',
		color: 'background.paper',
		backgroundColor: 'text.error',
		padding: '2px 6px',
		lineHeight: 'normal',
		display: headerBreak({ mobile: '', desktop: 'none' }),
	},
};
