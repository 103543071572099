import { SxProps, Theme } from '@mui/material';


export const accountSignoutHeadingSX: SxProps = {
    textAlign: 'left',
    paddingBottom: '16px',
    borderBottom: '1px solid',
    borderColor: 'border.grayDark',
    marginBottom: '24px',
    color: 'heading.dark',
};

export const accountSignoutMenuSX: SxProps<Theme> = {
	'& > a:first-child > .MuiMenuItem-root': {
		pt: 1,
	},
};

export const accountSignoutMenuItemSX: SxProps<Theme> = {
	'&.MuiMenuItem-root': {
		px: 1,
		py: 2,
		borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}`,
	},
	'&.MuiMenuItem-root.Mui-selected': {
		backgroundColor: 'background.modal',
	},
	'&:hover': {
		backgroundColor: 'background.modal',
	},
};

export const accountSignoutMenuListTypographySX: SxProps<Theme> = {
	whiteSpace: 'nowrap',
	overflowX: 'hidden',
	overflowWrap: 'break-word',
	wordWrap: 'break-word',
	wordBreak: 'break-word',
	color: 'text.main',
	fontSize: '18px',
	fontWeight: '400',
	lineHeight: '27px',
	'&:hover': {
		color: 'text.main',
		textDecoration: 'underline',
        textDecorationColor: 'text.textUnderline',
		backgroundColor: 'background.modal',
	},
};
