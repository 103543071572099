import { headerBreak } from '@/components/content/Header/styles/break';
import { SxProps } from '@mui/material';

export const custServiceSx: SxProps = {
	p: '1rem',
	color: 'text.main',
	fontSize: '14px',
	hr: {
		borderTop: '1px solid #ddd',
		borderBottom: 'none',
	},
};
