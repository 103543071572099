/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { HeaderNavBarDropMenuCustom } from '@/components/content/Header/parts/NavBarDropMenuCustom';
import { headerNavBarContainerSX } from '@/components/content/Header/styles/navBar/container';
import { LinkWrap } from '@/components/blocks/Linkable';
import { useNavigation } from '@/data/Navigation';
import { Container, Paper, Stack, Box, useTheme, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';

type Props = {

	setBgDropState: React.Dispatch<React.SetStateAction<boolean>>;

};
export const HeaderNavBarExtended: FC<Props> = ({ setBgDropState }) => {
	const { navigation } = useNavigation();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<Box sx={headerNavBarContainerSX} maxWidth={isMobile ? 'inherit' : 'none'}>
			<Container sx={{ position: 'relative' }}>
				<Stack direction="row" justifyContent="space-between" spacing={2}>
					{navigation?.filter(({ identifier }) => !identifier?.startsWith('_')).map(({ label, url, children, identifier }) => (
						<LinkWrap href={url} key={`${label}${url}`}>
							<HeaderNavBarDropMenuCustom setBgDropState={setBgDropState} tree={children} label={label} identifier={identifier} />
						</LinkWrap>
					))}
				</Stack>
			</Container>
		</Box>

	);
};
