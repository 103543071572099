/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import {
	Stack,
	Typography,
	useMediaQuery,
	Breakpoint,
	Tooltip,
	Button,
	useTheme,
	Drawer,
	Box,
	List,
	ListItemButton,
	ListItemText,
	ListItem,
} from '@mui/material';
import { FC, useCallback, useState, useEffect } from 'react';
import { Switch } from '@/utils/switch';
import { useLocalization } from '@/data/Localization';
import { useThemeSettings } from '@/styles/theme';
import PersonIcon from '@mui/icons-material/Person';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToReg';
import { HeaderAccountDropMenu } from '@/components/content/Header/parts/AccountDropMenu';
import { headerIconLabelSX } from '@/components/content/Header/styles/iconLabel';
import { headerNavBarDropMenuSX } from '@/components/content/Header/styles/navBar/dropMenu';
import { headerAccountContainerSX } from '@/components/content/Header/styles/account/container';
import { useUser } from '@/data/User';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { headerLinkSX } from '@/components/content/Header/styles/link';
import CloseIcon from '@mui/icons-material/Close';
import { headerDesktopDrawerButtonSX, headerDesktopDrawerSX } from '@/components/content/Header/styles/desktopDrawer';
import { useHeader } from '@/data/Content/Header';
import { ID } from '@/data/types/Basic';
import { getChildItem } from '@/utils/getChildItem';
import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import {
	HEADER_ACCOUNT_LINKS,
} from '@/data/constants/marketing';
import { SliderDrawer } from '@/utils/SliderDrawer';
import { AccountAfterSignInContent } from '@/components/content/Header/parts/AccountAfterSignInContent';
import { getOktaSignInOrderFormData, useOkta } from '@/data/Content/Okta';
import { useCart } from '@/data/Content/Cart';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


type Props = {
	mobileBreakpoint: Breakpoint;
	id: ID
};

export const HeaderAccountCustom: FC<Props> = ({ id, mobileBreakpoint = 'sm' }) => {
	const AccountLabels = useLocalization('SignInPage');
	const HeaderLabels = useLocalization('Header');
	const theme = useTheme();
	const { handleOktaAuthentication } = useOkta();
	const router = useNextRouter();
	const { getAdditive } = useThemeSettings();
	const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
	const [open, setOpen] = useState(false);
	const { user } = useUser();
	const isLoggedIn = user?.isLoggedIn ?? false;
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [signoutDrawerOpen, setSignoutDrawerOpen] = useState(false);
	const { contentItems } = useHeader(id);
	const { contentItem: accountLinks } = getChildItem(contentItems, HEADER_ACCOUNT_LINKS);
	const { count } = useCart();
	const welcomeMessage = isLoggedIn
		? user?.firstName
			? HeaderLabels.Actions.WelcomeFirstName.t({ firstName: user?.firstName })
			: HeaderLabels.Actions.WelcomeNoFirstName.t()
		: AccountLabels.Title.t();

	const handleToolTip = useCallback(
		(action?: string) => () =>
			setOpen((open) =>
				Switch(action)
					.case('open', () => true)
					.case('close', () => false)
					.defaultTo(() => !open)
			),
		[]
	);
	const toggleDrawer = useCallback(
		(open: boolean) =>
			(event: React.KeyboardEvent | React.MouseEvent) => {
				if (
					event.type === 'keydown' &&
					((event as React.KeyboardEvent).key === 'Tab' ||
						(event as React.KeyboardEvent).key === 'Shift')
				) {
					return;
				}
				if (!isLoggedIn) {
					setDrawerOpen(open);
				} else if (isLoggedIn) {
					setSignoutDrawerOpen(open);
				}
			}, [isLoggedIn]);
	useEffect(() => {
		if (!router.asPath) return;
		handleToolTip('close')();
	}, [handleToolTip, router.asPath]);

	const handleSignInClick = () => {
		const newOrderFormData = getOktaSignInOrderFormData(count);
		handleOktaAuthentication(newOrderFormData);
	};

	return (
		<>
			<Stack sx={headerAccountContainerSX} onMouseLeave={handleToolTip('close')}>
				<Tooltip
					placement="bottom"
					open={open}
					onClose={handleToolTip('close')}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					componentsProps={{
						tooltip: {
							sx: headerNavBarDropMenuSX,
						},
					}}
					title={
						false ? (
							<Stack direction="row">
								<HeaderAccountDropMenu />
							</Stack>
						) : null
					}
				>
					<Button
						aria-label={welcomeMessage}
						sx={headerLinkSX}
						onClick={toggleDrawer(true)}
						onKeyDown={toggleDrawer(true)}
					>
						<Stack
							alignItems="center"
							spacing={1}
							direction="row"
							onMouseEnter={isMobile === false ? handleToolTip('open') : undefined}
						>
							{isLoggedIn ? <HowToRegOutlinedIcon /> : <FontAwesomeIcon icon={faUser} color="#8D4960" />}
							<Typography variant="body2" sx={headerIconLabelSX}>{welcomeMessage}</Typography>
						</Stack>
					</Button>
				</Tooltip>
				{isMobile === false && isLoggedIn ? (
					<Button sx={getAdditive('coverTapTarget')} onClick={() => toggleDrawer(false)}>
						{welcomeMessage}
					</Button>
				) : null}
			</Stack>

			<Drawer
				anchor={'right'}
				open={drawerOpen}

			>
				<Box sx={headerDesktopDrawerSX}>
					<Stack direction={'row'} justifyContent={'space-between'}>
						<Typography variant={'h3'} component={'h3'}>
							{HeaderLabels.Actions.Account.t()}
						</Typography>
						<CloseIcon onClick={toggleDrawer(false)} sx={{ cursor: 'pointer' }} />
					</Stack>
					<Stack direction={'column'} >
						<List>
							<ListItem>
								<ListItemButton
									onClick={handleSignInClick}
									sx={headerDesktopDrawerButtonSX}
								>
									<ListItemText
										primary={
											<Typography
												variant="subtitle1"
											>
												{HeaderLabels.Actions.SignInCreateAccount.t()}
											</Typography>
										}
										sx={{ margin: 0 }}
									></ListItemText>
								</ListItemButton>
							</ListItem>
							{accountLinks ?
								<ContentRecommendation
									id={`${id}-${accountLinks?.emsName}`}
									properties={accountLinks}
								/> : null}
						</List>
					</Stack>
				</Box>

			</Drawer>
			<SliderDrawer isModalOpen={signoutDrawerOpen} setIsModalOpen={setSignoutDrawerOpen} childElement={<AccountAfterSignInContent setIsModalOpen={setSignoutDrawerOpen} />} />
		</>

	);
};
