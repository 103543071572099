import { useAdvantageEspots } from '@/data/Content/AdvantageEspots';
import { useContentEvents } from '@/data/Content/_ContentEvents';
import { renderContent } from '@/utils/renderContent';
import { Box } from '@mui/material';
import { FC } from 'react';

export const AdvantageLogo: FC = () => {
    const { advantageEspotLogo } = useAdvantageEspots();
    const { onContentClick } = useContentEvents();
    return <Box>
        {advantageEspotLogo?.map((content) => renderContent(content, onContentClick(content)))}
    </Box>;
};
